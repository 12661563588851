import { Injectable } from '@angular/core';
import swal from 'sweetalert2';

@Injectable({
    providedIn:'root'
})
export class ModalAvisosService{

    mostrarResultado(mensaje,icon) {
        swal.fire({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 10000,
            title: mensaje,
            icon:icon
        });
      }
    
      mostrarCargando(mensaje) {
        swal.fire({
                text:mensaje,
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                imageUrl: './assets/images/loading-9.gif'
              });
      }

      cerrarModal(){
        swal.close();
      }
}