import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Notificacion } from '../models/notificacion';
import { HttpHeaderApp } from "./header";
import { Observable } from 'rxjs';
import { HttpClient, } from "@angular/common/http";
import { ParamNotificacionProceso } from "../models/paramNotificacionProceso"
import { ParamNotificacionesRoles } from "../models/paramNotificacionesRoles"
import { NotificacionService } from 'src/app/services/notificacion.service';




@Injectable({
    providedIn: 'root'
})

export class GestionNotificaciones {

    private urlBack = environment.baseUrl;
    private header = this.headers.headerPublic;


    constructor(private notificacionService: NotificacionService, private http: HttpClient, private headers: HttpHeaderApp) {

    }

    eliminarNotificaciones(notificaiones: Notificacion[]): Observable<any> {
        return this.http.post<any>(`${this.urlBack}/notificaciones/eliminarNotificaciones`, notificaiones, { headers: this.header })
    }

    notificarPorRol(idTipoNotificacion, notificacion): void {
        let idUsuariosNotificar: string;
        let paramNotificacion: ParamNotificacionesRoles;
        this.obtenerNotificacion(idTipoNotificacion).subscribe(response => {
            idUsuariosNotificar = response.idUsuarios;
            paramNotificacion = response.paramNotificacion
            this.notificar(idUsuariosNotificar, notificacion, paramNotificacion)

        }, err => {
            console.log(err.error.error)
        })

    }

    obtenerTipoNotPorProceso(idProceso: number): Observable<ParamNotificacionProceso> {
        return this.http.get<ParamNotificacionProceso>(`${this.urlBack}/GestionNotificaciones/consultarTiposNotificacionProceso/${idProceso}`)
    }

    obtenerNotificacion(idTipoNotificacion: number): Observable<any> {
        return this.http.get<any>(`${this.urlBack}/GestionNotificaciones/consultarTiposNotificacionesRol/${idTipoNotificacion}`)
    }

    enviarNotificacionesCorreo(notificacion: Notificacion): void {
        this.http.post<any>(`${this.urlBack}/GestionNotificaciones/enviarCorreosUsuarios`, notificacion, { headers: this.header }).subscribe()
    }

    enviarNotificacionesCorreoArl(notificacion: Notificacion): void {
        this.http.post<any>(`${this.urlBack}/GestionNotificaciones/enviarCorreosArl`, notificacion, { headers: this.header }).subscribe()    
    }

    enviarNotificacionCentroFormacion(notificacion: Notificacion): void {
        this.http.post<any>(`${this.urlBack}/GestionNotificaciones/enviarCorreoCentroFormacion`, notificacion, { headers: this.header }).subscribe()    
    }


    notificar(idUsuariosNotificar, notificacion: Notificacion, paramNotificacion: ParamNotificacionesRoles): void {
        let roles = [];

        if ((typeof (idUsuariosNotificar) != 'object')) {
            if (paramNotificacion == null || paramNotificacion.notificarAplicacion) {
                this.notificacionService.sentNotification(idUsuariosNotificar, notificacion);
            }

            if (paramNotificacion.notificarCorreo) {
                this.enviarNotificacionesCorreo(notificacion);
            }
        } else {
            for (let i = 0; i < idUsuariosNotificar.length; i++) {
                notificacion.idUsuario = idUsuariosNotificar[i];
                if (paramNotificacion.notificarAplicacion) {
                    this.notificacionService.sentNotification(idUsuariosNotificar[i], notificacion);
                }

                if (paramNotificacion.notificarCorreo) {
                    this.enviarNotificacionesCorreo(notificacion);
                }
            };
        }
    }

    notificarUsuario(idUsuario, mensaje, notiAplicacion, notiCorreo, url): void {

        let notificacion: Notificacion = new Notificacion();
        let paramNotificacion = new ParamNotificacionesRoles();
        paramNotificacion.notificarAplicacion = notiAplicacion;
        paramNotificacion.notificarCorreo = notiCorreo;
        notificacion.notificacion = mensaje;
        notificacion.urlOpcion = url;
        notificacion.idUsuario = idUsuario;
        this.notificar(idUsuario, notificacion, paramNotificacion)
    }


    notificarCorreoArl(corroArl, mensaje): void {

        let notificacion: Notificacion = new Notificacion();
        notificacion.notificacion = mensaje;
        notificacion.correoNotificacionArl = corroArl;

        this.enviarNotificacionesCorreoArl(notificacion);
    }


    notificarCorreoCentroFormacion(corroArl, mensaje): void {

        let notificacion: Notificacion = new Notificacion();
        notificacion.notificacion = mensaje;
        notificacion.correoNotificacionArl = corroArl;

        this.enviarNotificacionCentroFormacion(notificacion);
    }

}