import {HttpClient,HttpHeaders} from "@angular/common/http";
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators'
import { Observable, of, throwError } from 'rxjs';
import { MenuItem } from '../views/layout/navbar/menu.model';
import { environment } from 'src/environments/environment';
import { ThisReceiver } from "@angular/compiler";


@Injectable({
    providedIn:'root'
})
export class MenuService{

     urlBack= environment.baseUrl;
     sufijo=environment.sufijo;
     private httHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });

    constructor(private http:HttpClient){

    }

    public authenticar(loginData: any){
        return this.http.get(`${this.urlBack}/auth/autenticar`,loginData);
    }


    getMenu(): Observable<any> {
        console.log("aqui entramos")
        return this.http.get(`${this.urlBack}/menu/consultarMenu`)
      }
}
