import { Notificacion } from "./notificacion";

export class DatosSesion{
    usuario:string;
    token:string;
    cambiar:boolean;
    isLoggedin:string;
    notificaciones:Notificacion[]
    id:number;
    rolNombre:string;
}