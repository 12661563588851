import { HttpInterceptor,HttpRequest,HttpHandler,HttpEvent, HTTP_INTERCEPTORS } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DatosSesion } from "../models/datosSesion";

@Injectable()
export class AuthInterceptor implements HttpInterceptor{
    datosSesion:DatosSesion;
    constructor (){
        /*
        this.datosSesion=new DatosSesion();
        if(localStorage.getItem('datosSesion')!=undefined && localStorage.getItem('datosSesion')!=null){
            this.datosSesion=JSON.parse(localStorage.getItem('datosSesion'))
        }*/
        //alert("const")
    }

    intercept(req:HttpRequest<any>,next:HttpHandler):Observable<HttpEvent<any>>{
        this.datosSesion=new DatosSesion();
        if(localStorage.getItem('datosSesion')!=undefined && localStorage.getItem('datosSesion')!=null){
            this.datosSesion=JSON.parse(localStorage.getItem('datosSesion'))
        }
       // alert("intercept")
        let authReq=req;
        const token=this.datosSesion.token;
        if(token!=null && token!=undefined){
            authReq=authReq.clone({                           
                setHeaders:{Authorization:`Bearer ${token}`}
            })
        }
        return next.handle(authReq);
    }
}

export const AuthInterceptorProviders=[
    {
        provide:HTTP_INTERCEPTORS,
        useClass:AuthInterceptor,
        multi:true
    }
]