import { Injectable } from '@angular/core';
import { Stomp,Client} from '@stomp/stompjs';
import * as SockJS from 'sockjs-client';
import { environment } from 'src/environments/environment';
import { Notificacion } from '../models/notificacion';
import { ParamNotificacionesRoles } from '../models/paramNotificacionesRoles';


@Injectable({
    providedIn:'root'
})

export class NotificacionService{

    private stomClient:any;
    private urlBack=environment.baseUrl.split("//")[1];
    private client: Client;

  
    constructor(){
    }


    initConectionSocket() {
        
        const url = `//${this.urlBack}/notificacion-socket`;
        const wsFactory = () => new SockJS(url);
        this.client = Stomp.over(wsFactory); // Proporciona el factory al crear el cliente STOMP
        this.client.activate();

        this.client.onConnect = (frame) => {
        console.log('Conectado al servidor WebSocket');
        };

      }

      disconnect() {
        if (this.client.connected) {
          this.client.deactivate();
        }
      }
    
      sentNotification(roomId:string,notificacion:Notificacion) {

        if (this.client && this.client.connected) {
            const destination = `/app/notificacion/${roomId}`;
            this.client.publish({ destination, body: JSON.stringify(notificacion) });
        } else {

          setTimeout(() => {
            console.log('Se ejecuta después de 3 segundos');
            this.sentNotification(roomId,notificacion);
          }, 1000);
            console.error('El cliente STOMP no está conectado');
        }


      }
    
      subscribeToNotifications(roomId: number, callback: (message: string) => void) {
        const destination = `/topic/${roomId}`;
        this.client.subscribe(destination, (message) => {
          callback(message.body);
          console.log("Notificacion ",message.body)
        });
      }

}