import { Injectable } from '@angular/core';
import { CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Router } from '@angular/router';
import { DatosSesion } from 'src/app/models/datosSesion';
import { NotificacionService } from 'src/app/services/notificacion.service';

@Injectable()
export class AuthGuard implements CanActivate {
  datosSesion:DatosSesion;

  constructor(private router: Router,private notificacionService:NotificacionService,) {
    this.datosSesion=new DatosSesion();
    if(localStorage.getItem('datosSesion')!=undefined && localStorage.getItem('datosSesion')!=null){
      this.datosSesion=JSON.parse(localStorage.getItem('datosSesion'))
    }
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.datosSesion.isLoggedin && this.datosSesion.token ) {
      // logged in so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
    
     localStorage.removeItem('datosSesion');
     this.notificacionService.disconnect();
     this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }
}