import {HttpClient,HttpHeaders} from "@angular/common/http";
import { Injectable } from '@angular/core';
import { environment } from "src/environments/environment";

@Injectable({
    providedIn:'root'
})
export class LoginService{

    urlBack = environment.baseUrl;
     private httHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });

    constructor(private http:HttpClient){

    }

    public authenticar(loginData: any){
        return this.http.post(`${this.urlBack}/auth/autenticar`,loginData);
    }

    public solicitarCodigo(datosRecuperacion: any){
        return this.http.post(`${this.urlBack}/auth/solictarCodigoRecuperacion`,datosRecuperacion);
    }

    public actualizarClave(datosRecuperacion: any){
        return this.http.post(`${this.urlBack}/auth/actualizarClave`,datosRecuperacion);
    }

    public actualizarClavePrimera(nuevaClave:string){
        return this.http.post(`${this.urlBack}/auth/actualizarClavePrimera`,nuevaClave);
    }

}
