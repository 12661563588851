import { Injectable } from '@angular/core';
import { DatosSesion } from '../models/datosSesion';
import { Notificacion } from '../models/notificacion';

@Injectable({
    providedIn:'root'
})
export class ManejoDatosSesion{

    datosSesion:DatosSesion;

    actualizarNotificacionesSesion(notificaciones:Notificacion[]):void{
       this.obtenerDatosSesion();
        this.datosSesion=JSON.parse(localStorage.getItem('datosSesion'))
        this.datosSesion.notificaciones=notificaciones;
        localStorage.removeItem('datosSesion');
        this.guardarDatosSesion(this.datosSesion);
    }

    obtenerDatosSesion():any{
        this.datosSesion= JSON.parse(localStorage.getItem('datosSesion'));
        return this.datosSesion;
    }

    guardarDatosSesion(datosSesion):void{
        localStorage.setItem('datosSesion',JSON.stringify(datosSesion));
    }

}