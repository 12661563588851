import { Component, OnInit, Inject, Renderer2, HostListener,ViewChild  } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MenuItem } from './menu.model';
import {MenuService} from "../../../services/menu.service"
import { Utils } from 'src/app/core/util/util';
import { ModalAvisosService } from 'src/app/services/modalAvisosService';
import { NotificacionService } from 'src/app/services/notificacion.service';
import { DatosSesion } from 'src/app/models/datosSesion';
import { Notificacion } from 'src/app/models/notificacion';
import { DatePipe } from '@angular/common';
import { ManejoDatosSesion } from 'src/app/services/manejoDatosSesion';
import { GestionNotificaciones } from 'src/app/services/gestionNotificaciones.service';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  menuItems = [];
  @ViewChild('modalCambiarClave') modalContent: any;

  validaciones=Utils.validaciones;
  clavesIguales:boolean=true;
 // nombreUsuario:string;
  datosSesion:DatosSesion;
  notificaciones:Notificacion[];

   datePipe: DatePipe=new DatePipe('en-US');

  datosCambio={
    "usuario":"",
    "codigo": "",
    "clave1":"",
    "clave2":""
  }

  /**
  * Fixed header menu on scroll
  */
  @HostListener('window:scroll', ['$event']) getScrollHeight(event) {    
    if (window.matchMedia('(min-width: 992px)').matches) {
      let header: HTMLElement = document.querySelector('.horizontal-menu') as HTMLElement;
      if(window.pageYOffset >= 60) {
        header.parentElement.classList.add('fixed-on-scroll')
      } else {
        header.parentElement.classList.remove('fixed-on-scroll')
      }
    }
  }

  constructor(
    @Inject(DOCUMENT) private document: Document, 
    private renderer: Renderer2,
    private router: Router,
    private menuService:MenuService,
    private modalService: NgbModal,
    private loginService:LoginService,
    private servicioModal:ModalAvisosService,
    private notificacionService:NotificacionService,
    private manejoDatosSesion:ManejoDatosSesion,
    private gestionNotificaciones:GestionNotificaciones
  ) { }

  ngOnInit(): void {
    this.notificacionService.initConectionSocket();
    this.datosSesion=JSON.parse(localStorage.getItem('datosSesion'))
    this.notificaciones=this.datosSesion.notificaciones;
    console.log('this.datosSesion:', this.datosSesion);

   // this.nombreUsuario=localStorage.getItem('usuario')

    this.menuService.getMenu().subscribe(menu => {
      this.menuItems = menu;

      if(this.datosSesion.cambiar){
        this.modalService.open(this.modalContent, { backdrop: 'static' });     
      }   
      if (window.matchMedia('(max-width: 991px)').matches) {
        this.router.events.forEach((event) => {
          if (event instanceof NavigationEnd) {
            document.querySelector('.horizontal-menu .bottom-navbar').classList.remove('header-toggled');
          }
        });
      } 
      if(this.menuItems.length===0){
        this.onLogout(event);
      } 

        
        this.notificacionService.subscribeToNotifications(this.datosSesion.id, (message) => {
          console.log('Notificación recibida:', message);
          this.notificaciones.push(JSON.parse(message));
          this.manejoDatosSesion.actualizarNotificacionesSesion(this.notificaciones);
        });
  
      
    },err=>{
      if(this.menuItems.length===0){
        this.onLogout(event);
      } 
    })


  }

  /**
   * Returns true or false if given menu item has child or not
   * @param item menuItem
   */
  hasItems(item: MenuItem) {
    return item.subMenus !== undefined ? item.subMenus.length > 0 : false;
  }

  /**
   * Logout
   */
  onLogout(e) {
    e.preventDefault();
    localStorage.removeItem('datosSesion');
    this.notificacionService.disconnect();
    if (!localStorage.getItem('datosSesion')) {
      this.router.navigate(['/auth/login']);
    }
  }

  /**
   * Toggle header menu in tablet/mobile devices
   */
  toggleHeaderMenu() {
    document.querySelector('.horizontal-menu .bottom-navbar').classList.toggle('header-toggled');
  }


  cambiarClave():void{
    console.log(this.datosCambio)
    this.servicioModal.mostrarCargando("Cargando...")
    this.loginService.actualizarClavePrimera(this.datosCambio.clave1).subscribe(response=>{
      this.servicioModal.cerrarModal()
      this.servicioModal.mostrarResultado("Contraseña cambiada correctamente","success")
      this.datosSesion=this.manejoDatosSesion.obtenerDatosSesion()
      this.datosSesion.cambiar=false;
      this.manejoDatosSesion.guardarDatosSesion(this.datosSesion)
      this.modalService.dismissAll()
      
    },err=>{
      this.servicioModal.cerrarModal()
      this.servicioModal.mostrarResultado("Ocurrió un error al cambiar la contraseña "+err.error.error,"error")
    }
    )
  }

  validarClavesIguales():void{
    this.clavesIguales=this.datosCambio.clave1===this.datosCambio.clave2;
  }

  eliminarNotificacion(notificaciones:Notificacion):void{
    let notificionesEliminar=[notificaciones]
    this.eliminarNotificaciones(notificionesEliminar,event);
  }

  eliminarNotificaciones(notificaciones:Notificacion[],event: Event): void{
    event.preventDefault();

    this.gestionNotificaciones.eliminarNotificaciones(notificaciones).subscribe(response=>{
      this.notificaciones=response.notificaciones;
      this.manejoDatosSesion.actualizarNotificacionesSesion(this.notificaciones);
    },err=>{
        this.servicioModal.mostrarResultado("Error "+err.error.error,"error")
    })
  }

}
